export default function Specials() {
    return (
    <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
  <li>
    <div className="timeline-middle">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-success">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
</svg>


    </div>
    <div className="timeline-end text-stone-700">
      <div className="text-md font-black">Web Accessibility</div>
      <p className="text-xs">
      Focusing on web accessibility, I ensure that digital experiences are user-friendly for individuals with disabilities, prioritizing equal access and usability. Implementing best practices and standards, my goal is to create inclusive websites that cater to diverse user needs.</p>
    </div>
    <hr className="tinyW"/>
  </li>
  <li>
    <hr  className="tinyW"/>
    <div className="timeline-middle">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-success">
  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
</svg>

    </div>
    <div className="timeline-start md:text-end text-stone-700">
      <div className="text-md font-black">Data Management</div>
      <p className="text-xs">
      Proficient in data management, I build robust RESTful APIs, implement secure user authentication, and handle data manipulation that suits your needs. My focus is on creating seamless data interactions to ensure secure and efficient information flow within web applications.
      </p>
    </div>
    <hr  className="tinyW"/>
  </li>
  <li>
    <hr  className="tinyW"/>
    <div className="timeline-middle">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-success">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
</svg>

    </div>
    <div className="timeline-end text-stone-700">
    <div className="text-md font-black">Responsive Design</div>
      <p className="text-xs">
      Crafting web experiences that effortlessly adapt to diverse screen sizes, my expertise in Responsive Design guarantees your applications will always look polished and perform flawlessly across all devices. Even this website, is designed to be great looking on both your phone and laptop! </p>
    </div>
    <hr  className="tinyW"/>
  </li>
  
</ul>
    )
}