import selfie from '../img/selfie2.png';
import mouse from '../img/icons/scrolldown.png';
import { motion } from 'framer-motion';

export default function Landing({ navRef }) {
  return (
    <>
      <div className='relative tileBackground shadow-2xl shadow-black' id='home'>
        <div className="hero pb-8 md:py-8 md:mb-0">
          <div className="hero-content md:w-4/5 flex-col md:flex-row-reverse px-0 mt-16">
            <img src={selfie} className="md:w-1/2 lg:w-2/5 rounded-full border-l-2 border-stone-700" alt='Selfie of Cody Cooper' />
            <div className='mx-6 md:mx-14'>
              <p className='text-stone-200'>Hello! My name is</p>
              <h1 className="text-5xl customFont text-stone-200" ref={navRef}>Cody Cooper</h1>
              <h2 className="text-2xl text-warning">Full Stack Web Developer</h2>
              {/* <p className="py-6 text-stone-500">For the past year, I have dedicated myself to building a strong foundation in both front-end and back-end web development. Currently, I am employed by the State of Missouri.</p> */}
              {/* <p className="py-6 text-stone-500">I build responsive and dynamic web applications. With a robust customer service background, I offer a valuable blend of skills to any team. Currently, I am contributing my skills to the people of Missouri.</p> */}
              <p className="py-6 text-stone-500">Experienced in developing responsive and dynamic full-stack web applications, I bring a versatile skill set that merges technical expertise with a strong foundation in customer service and management. I welcome challenges and thrive in fast-paced environments.</p>
              {/* <a className="btn btn-secondary rounded-none">Contact Me</a><a className="btn btn-ghost rounded-none lg:ml-6">View My Work</a> */}
              <a href='#about'>
                <motion.img animate={{ y: [0, -6, 0], transition: { duration: 2, repeat: Infinity } }} className='w-12 h-12 opacity-60 hover:opacity-90' src={mouse} alt='Scroll down to continue'></motion.img>
              </a>
              {/* <p>Scroll down to continue.</p> */}
            </div>
          </div>
        </div>
        {/* <div className='absolute bottom-2 left-1/2 transform -translate-x-1/2 animate-bounce text-xs opacity-30'>
          &#9660;
      </div> */}
      </div>
      <div className='relative'>
        <div className='absolute h-16 md:h-44 clippedUp tileBackground w-full'></div>
      </div>
    </>
  )
};