import { easeOut, motion } from "framer-motion";
import standingPic from '../img/standing3.png';
import Specials from "./Specials";

export default function About() {
    return (
        <div className="bg-stone-200 clippedDown shadow-2xl shadow-black border-b-2 border-stone-200" id="about">
            <div className="md:w-5/6 mx-auto px-4 md:px-0">
                <div className="pt-24 md:pb-12 md:grid md:grid-cols-12">
                    <div className="col-span-6 my-auto hidden md:block mx-auto">
                            <img className="mt-12 mx-auto" src={standingPic} alt="Cody standing with Lenovo Laptop"></img>
                            {/* <div className=" mx-20 text-center text-xs my-2 text-black">
                                <p>Growing up, I enjoyed woodshop competitions and excelled in math, finding ease in challenging courses. Eventually, I discovered my passion for software development, earning a certificate in Full Stack web development to establish a solid foundation. Since then, I've been dedicated to honing my skills and exploring different areas to find the right fit for my professional journey.</p>
                            </div> */}
                    </div>
                    <div className="col-span-6">
                        <div className="col-span-12">
                            <motion.div
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ ease: easeOut, duration: 1 }}
                                className='my-16'>
                                <h2 className='text-4xl customFont text-left text-stone-900'>&#x2756; ABOUT</h2>
                                <p className="text-stone-700 text-sm"><strong>I am currently employed as a Web Developer with the State of Missouri</strong>, where my role involves designing and maintaining fifteen different departmental web applications aimed at serving the needs of the public. Additionally, I engage in freelance work, creating websites for small local businesses.
                                </p>
                            </motion.div>
                        </div>
                        <div className="col-span-12">
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ ease: easeOut, duration: 1 }}
                                className='my-16'>
                                <Specials />
                            </motion.div>
                        </div>
                        <div className="col-span-12 md:hidden ">
                            <img className="mt-12 border-b border-stone-500" src={standingPic} alt="Cody standing with Lenovo Laptop"></img>
                        </div>
                        <div className="col-span-12">
                            <motion.div
                                initial={{ opacity: 0, x: 75 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ ease: easeOut, duration: 1 }}
                                className='pt-4 pb-16 md:pb-6'>
                                <p className="text-stone-700 text-sm">Throughout my life, I've consistently found joy in problem-solving, mathematics, and the continuous pursuit of learning. Eventually, I discovered my passion for software development, earning a certificate in Full Stack web development to establish a solid foundation. Since then, I've been dedicated to honing my skills and exploring different areas to expand my skill set. <strong>I'm a firm believer that success is achieved through hard work, determination, and a commitment to learning.</strong> While I enjoy tackling problems independently and using tools to bring ideas to life, I also recognize the power of teamwork. Outside of work, you'll find me engaged in various hobbies, such as woodworking, mountain biking, fishing, and bowling.</p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};