import reactIcon from '../img/icons/react.png';
import nodeIcon from '../img/icons/node.png';
import bashIcon from '../img/icons/bash.png';

export default function Skills() {
    return (
        <>
            <div className='relative'>
                <div className='absolute h-24 w-full z-20 -top-0 bg-stone-200 clippedUpL'></div>
                <div className='absolute h-24 w-full z-20 -top-1 bg-stone-200 clippedUpL'></div>
            </div>
            <div className="tileBackground2 clippedDownL" id="skills">

                <div className="md:w-full mx-auto py-24 md:px-12">

                    <h2 className='text-4xl py-6 customFont text-center text-stone-200'>&#x2756; SKILLS</h2>
                    <div className="flex flex-row flex-wrap justify-center">

                        <div className="card w-3/4 md:w-72 m-6 bg-opacity-90 bg-stone-900 shadow shadow-stone-700 rounded-none">
                            <div className="card-body">
                                <img src={reactIcon} className='w-20 h-20 mx-auto' alt='React Icon symbolizing front end skills category'></img>

                                <h3 className="card-title mx-auto customFont2 font-normal text-2xl text-stone-300">Frontend</h3>
                                <div className='flex justify-center'>
                                <ul className="text-center font-thin text-stone-400">
                                    <li>HTML</li>
                                    <li>CSS</li>
                                    <li>Javascript</li>
                                    <li>React</li>
                                    <li>Bootstrap</li>
                                    <li>Tailwindcss</li>
                                    <li>DaisyUI</li>
                                    <li>Nextjs</li>
                                </ul>
                                </div>
                            </div>
                        </div>


                        <div className="card w-3/4 md:w-72 m-6 bg-opacity-90 bg-stone-900 shadow shadow-stone-700 rounded-none ">
                            <div className="card-body">
                                <img src={nodeIcon} className='w-20 h-20 mx-auto' alt='Node Icon symbolizing back end skills category'></img>

                                <h3 className="card-title mx-auto customFont2 font-normal text-stone-300 text-2xl">Backend</h3>
                                <div className='flex justify-center'>
                                <ul className="text-center font-thin text-stone-400">
                                    <li>Node</li>
                                    <li>PHP</li>
                                    <li>Express</li>
                                    <li>Apache</li>
                                    <li>MongoDB</li>
                                    <li>MySQL</li>
                                    <li>GraphQL</li>
                                    <li>REST APIs</li>
                                </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card w-3/4 md:w-72 m-6 bg-opacity-90 bg-stone-900 shadow shadow-stone-700 rounded-none">
                            <div className="card-body">
                                <img src={bashIcon} className='w-20 h-20 mx-auto' alt='Bash Icon symbolizing other skills category'></img>

                                <h3 className="card-title mx-auto customFont2 font-normal text-stone-300 text-2xl">Misc</h3>
                                <div className='flex justify-center'>
                                <ul className="text-center font-thin text-stone-400">
                                    <li>Typescript</li>
                                    <li>Git</li>
                                    <li>Bash</li>
                                    <li>VSCode</li>
                                    <li>Linux</li>
                                    <li>Figma</li>
                                    <li>Google Cloud Storage</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative'>
                <div className='absolute h-16 md:h-44 clippedUp tileBackground w-full'></div>
            </div>
        </>
    )
};