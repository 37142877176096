import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { validate } from "email-validator";

export default function Contact() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const form = useRef();

    const handleBlur = (e) => {
        const { target } = e;
        const inputType = target.name;
        const inputValue = target.value;

        if (inputType === "user_name" && !inputValue) {
            setErrorMessage("Please enter your name!");
            return;
        } else if (inputType === "user_email" && !validate(inputValue)) {
            setErrorMessage("Please enter a valid email!");
            return;
        } else if (inputType === "message" && !inputValue) {
            setErrorMessage("Please provide a message!");
            return;
        } else if (inputType === "selectOption" && !inputValue) {
            setErrorMessage("Please pick a subject option!");
            return;
        } else {
            setErrorMessage("");
        }

    };

    const handleInputChange = (e) => {
        const { target } = e;
        const inputType = target.name;
        const inputValue = target.value;

        setSuccessMessage("");
        setErrorMessage("");

        switch (inputType) {
            case "user_name":
                setName(inputValue);
                break;
            case "user_email":
                setEmail(inputValue);
                break;
            case "message":
                setMessage(inputValue);
                break;
            case "selectOption":
                setSubject(inputValue);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && email && message && subject) {
            emailjs.sendForm('service_7k52s6i', 'template_dhwv5mr', form.current, '3N05w40sHCa9AFukg')
                .then((result) => {
                    setSuccessMessage("Your message has been sent!");
                }, (error) => {
                    setErrorMessage("There has been an error sending your message.");
                });
            setName("");
            setEmail("");
            setMessage("");
            setSubject("");
            return;
        } else {
            setErrorMessage("Please provide required information");
            return;
        }
    };

    return (
        <>
            <div className='relative'>
                <div className='absolute h-24 w-full z-20 -top-0 bg-stone-200 clippedUpL'></div>
                <div className='absolute h-24 w-full z-20 -top-1 bg-stone-200 clippedUpL'></div>
            </div>
            <div className='relative'>
                <div className='absolute h-24 w-full z-20 -top-0 bg-stone-200 clippedUpL'></div>
                <div className='absolute h-24 w-full z-20 -top-1 bg-stone-200 clippedUpL'></div>
            </div>
            <div className="tileBackground2 clippedDownL" id="contact">
                <div className="hero min-h-screen md:w-3/5 mx-auto">
                    <div className="hero-content flex-col lg:flex-row-reverse mt-16">
                        <div className="text-center lg:text-left text-stone-300">
                            <h1 className="text-4xl customFont">&#x2756; CONTACT</h1>
                            <p className="py-6">Feel free to reach out and share your thoughts, inquiries, or collaboration ideas. I look forward to connecting with you!</p>
                        </div>
                        <div className="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100 rounded-sm">
                            <form ref={form} onSubmit={handleSubmit} className="card-body">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Name</span>
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="user_name"
                                        value={name}
                                        onChange={handleInputChange}
                                        onBlur={(e) => handleBlur(e)}
                                        placeholder="Full Name"
                                        className="input input-bordered rounded-sm"
                                        required />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Email</span>
                                    </label>
                                    <input
                                        type="email"
                                        name="user_email"
                                        value={email}
                                        onChange={handleInputChange}
                                        onBlur={(e) => handleBlur(e)}
                                        placeholder="Email address"
                                        className="input input-bordered rounded-sm"
                                        required />
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Subject</span>
                                    </label>
                                    <select
                                        onChange={handleInputChange}
                                        onBlur={(e) => handleBlur(e)}
                                        id="selectOption"
                                        className="input input-bordered rounded-sm"
                                        name="selectOption"
                                        defaultValue=""
                                    >
                                        <option value="" disabled>Select an option</option>
                                        <option value="customWebsite">Custom Website Inquiry</option>
                                        <option value="collaboration">Collaboration Opportunity</option>
                                        <option value="jobOffer">Job Offer</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Message</span>
                                    </label>
                                    <input
                                        autoComplete="off"
                                        placeholder="Send me a message"
                                        className="input input-bordered rounded-sm"
                                        name="message"
                                        value={message}
                                        onChange={handleInputChange}
                                        onBlur={(e) => handleBlur(e)}
                                        required
                                    ></input>
                                </div>
                                <div className="form-control mt-6">
                                    <button className="btn btn-success" type="submit">Send</button>
                                </div>
                            </form>
                            {errorMessage && (
                                <p className="text-center mb-4 text-red-500">
                                    {" "}
                                    {errorMessage}{" "}
                                </p>
                            )}
                            {successMessage && (
                                <p className="text-center mb-4 text-green-600">
                                    {successMessage}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}