import { motion } from "framer-motion";
import githubLogo from "../img/icons/github-logo.png";

export default function Navbar({ navVisible }) {
  return (
    <motion.div 
    initial={{ opacity: 0 }}
    animate={navVisible ? { opacity: 1 } : { opacity: 0, }}
    transition={{ duration: 1 }}
    className='navbar fixed z-50 bg-stone-950 text-stone-400 customFont2 tracking-wider'>
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </label>
          <ul
            tabIndex={0} className="menu menu-lg dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 w-52">
            <li><a href="#home" className="hover:rounded-none">HOME</a></li>
            <li><a href="#about" className="hover:rounded-none">ABOUT</a></li>
            <li><a href="#skills" className="hover:rounded-none">SKILLS</a></li>
            <li><a href="#projects" className="hover:rounded-none">PROJECTS</a></li>
            <li><a href="#contact" className="hover:rounded-none">CONTACT</a></li>
          </ul>
        </div>
        <p className="text-3xl md:text-4xl customFont"><a href="#home"><span className="inline-block transform -rotate-45 text-success">C</span><span className="inline-block transform rotate-45">C</span></a></p>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 ">
          <li><a href="#home" className="hover:rounded-none">HOME</a></li>
          <li><a href="#about" className="hover:rounded-none">ABOUT</a></li>
          <li><a href="#skills" className="hover:rounded-none">SKILLS</a></li>
          <li><a href="#projects" className="hover:rounded-none">PROJECTS</a></li>
          <li><a href="#contact" className="hover:rounded-none">CONTACT</a></li>
        </ul>
      </div>
      <div className="navbar-end">
        <a href="https://github.com/Ccooper9893"><img src={githubLogo} className="w-8 h-8 mr-4 opacity-80 hover:opacity-100" alt="Github Logo"/></a>
      </div>
    </motion.div>
  )
};