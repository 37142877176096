import bringPopcornV2 from '../img/screenshots/bringpopcornv2screenshot.webp';
import farmersMarket from '../img/screenshots/farm-market-screenshot.webp';
import saintsAndScissors from '../img/screenshots/saints-and-scissors-screenshot.webp';
import omegaBlogs from '../img/screenshots/omega-blogs.webp';

export default function Projects() {
    return (
        <>
            <div className='bg-stone-200 clippedDownProjects clippedDown'>
                <div className="md:w-5/6 mx-auto px-4 md:px-10 py-24 md:pt-24 md:px-0">

                    <h2 className='text-4xl pt-6 customFont text-left text-stone-900' id='projects'>&#x2756; PROJECTS</h2>

                    <div className="md:pb-12 md:grid md:grid-cols-12">

                        <div className='col-span-10'>

                            <div className="card col-span-12 lg:card-side bg-base-100 shadow-xl shadow-black rounded-md mt-12">
                                <figure><img className='shadow-inner shadow-base-100' src={bringPopcornV2} alt="Bring Popcorn Version 2 web application project" /></figure>
                                <div className="card-body wCustom mx-auto bg-stone-900">
                                    <h2 className="card-title customFont2 font-normal text-3xl text-stone-300">Bring Popcorn 2.0</h2>
                                    <p className='text-sm text-stone-400'>Introducing a sleek movie exploration web app, crafted with Next.js and IMDB's API. Built with React, DaisyUI, tailwindcss, and JavaScript, it makes finding where to stream, rent, or buy movies a breeze. The user-friendly interface ensures a seamless and SEO-friendly experience, guiding you to your favorite movies effortlessly. Stay tuned for future upgrades, including a back-end for user reviews, ratings, and more, enhancing your movie exploration journey. <a className=" hover:underline" href='https://nbadraun.github.io/Bring-Popcorn/' rel='noreferrer' target='_blank'> Version 1.0                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-4 h-6 inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                        </svg>
                                    </a></p>
                                    <div className="card-actions justify-end mt-3">
                                    <a href='https://github.com/Ccooper9893/popcorn-flix' className="btn text-xs rounded-sm hover:underline">View Code</a>
                                    <a href='https://popcorn-flix.vercel.app/' className="btn btn-success rounded-sm">Live Site</a>
                                    </div>
                                </div>
                            </div>

                            <div className="card col-span-12 lg:card-side bg-base-100 shadow-xl shadow-black rounded-md mt-12">
                                <figure><img className='shadow-inner shadow-base-100' src={farmersMarket} alt="Farmers Market web application project" /></figure>
                                <div className="card-body wCustom mx-auto bg-stone-900">
                                    <h2 className="card-title customFont2 font-normal text-3xl text-stone-300">Farmer's Market</h2>
                                    <p className='text-sm text-stone-400'>Eating healthy means eating local. Built on MongoDB, Express, React, and integrated with Google Cloud Storage, our farmers market website empowers farmers to showcase their products. Utilizing JSON web tokens, Google Cloud Storage, and GraphQL, Farmer's Market provides a seamless experience for farmers to list their offerings, elevating the way farmers connect with customers and market their products. Test user: dansfarm@email.com/password123</p>
                                    <div className="card-actions justify-end mt-3">
                                    <a href='https://github.com/Ccooper9893/Farmers-Market-Shop' className="btn text-xs rounded-sm hover:underline">View Code</a>
                                    <a href='https://farmers-market-47450d2fa3f3.herokuapp.com' className="btn btn-success rounded-sm">Live Site</a>
                                    </div>
                                </div>
                            </div>

                            <div className="card col-span-12 lg:card-side bg-base-100 shadow-xl shadow-black rounded-md mt-12">
                                <figure><img className='shadow-inner shadow-base-100' src={saintsAndScissors} alt="Saints and Scissors Salon website" /></figure>
                                <div className="card-body wCustom mx-auto bg-stone-900">
                                    <h2 className="card-title customFont2 font-normal text-3xl text-stone-300">Saints &amp; Scissors</h2>
                                    <p className='text-sm text-stone-400'>Paid for by Saints and Scissors, this website reflects a stylistic approach tailored for the salon's unique business style. Constructed using React, Daisy UI, and Tailwind CSS, it marks my first paid venture into freelancing. The minimalist yet effective landing page serves as a digital storefront, showcasing the salon's services and connecting with clients.</p>
                                    <div className="card-actions justify-end mt-3">
                                        <a href='https://saintsandscissors.com/' className="btn btn-success rounded-sm">Live Site</a>
                                    </div>
                                </div>
                            </div>

                            <div className="card col-span-12 lg:card-side bg-base-100 shadow-xl shadow-black rounded-md mt-12">
                                <figure><img className='shadow-inner shadow-base-100' src={omegaBlogs} alt="Omega Blogging website" /></figure>
                                <div className="card-body wCustom mx-auto bg-stone-900">
                                    <h2 className="card-title customFont2 font-normal text-3xl text-stone-300">Omega Blogs</h2>
                                    <p className='text-sm text-stone-400'>Omega Blogs is a space in which you can share your ideas and gain knowledge from others. Created using Javascript, MySQL, Express, Handlebars, and Bootstrap. Omega Blogs utilizes CRUD operations and session cookies to persist user information to ensure a seamless and secure user experience. Test user: cody/password123</p>
                                    <div className="card-actions justify-end mt-3">
                                    <a href='https://github.com/Ccooper9893/Omega-Blogs' className="btn text-xs rounded-sm hover:underline">View Code</a>
                                    <a href='https://omega-blogging-0dd1a7b77ce5.herokuapp.com/' className="btn btn-success rounded-sm">Live Site</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}