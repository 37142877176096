import { useRef, useState, useEffect } from 'react';
import { useInView } from 'framer-motion';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';

import './App.css';

function App() {

  const navRef = useRef(null);
  const isInView = useInView(navRef);
  const [navVisible, setNavVisible] = useState(false);

  useEffect(() => {
    if (!isInView) {
      setNavVisible(true);
    } else {
      setNavVisible(false);
    };
  }, [isInView]);

  return (
    <div className='woodBackground'>
      <header>
        <Navbar navVisible={navVisible}/>
      </header>
      <Landing navRef={navRef} />
      <About/>
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
